<script>
import makeSummary from '#/shop-order/helpers/make-summary'
export default {
	props: ['order'],
	computed: {
		summary() {
			return makeSummary(this.order)
		},
		joinedItems() {
			return this.summary.getJoinedItems()
		},
	},
	hooksKey: 'OrderSummary',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
}
</script>

<template>
	<v-card v-if="order">
		<hook zone="above" />
		<div class="pa-2 pa-sm-5">
			<template v-for="(jItem, i) of joinedItems">
				<OrderSummary-Item :order="order" :joined-item="jItem" :key="`i${i}`" />
				<v-divider v-if="i < joinedItems.length - 1" :key="`d${i}`" />
			</template>
			<hook zone="below-items" />
			<OrderSummary-Totals :order="order" :summary="summary" class="pt-6" />
		</div>
		<hook zone="below" />
	</v-card>
</template>

<style></style>

